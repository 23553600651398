<template>
	<div>
		<div>
			<!-- <div class="mess-w" v-if="details !== true">
				<div class="bode_right">
					<div class="headmassage">
						<a>{{ categorydata }}</a>
					</div>
					<div class="center1-lm2" id="containet" v-cloak>
						<ul id="pageMain">
							<li v-for="(item,key) in datafrom" :key="key">
								<a target="_blank" :title="item.title" @click="opentitle(item)">{{ item.title }}</a>
							</li>
						</ul>
					</div>
					<div class="block">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="current" :page-sizes="sizes" :page-size="size"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div> -->

			<div style="margin-top: 15px;padding-left: 15px;padding-right: 15px;" v-if="details !== true">
				<div class="dn"
					style="height: 40px;display: inline-block;font-size: 23px;color: #0088cc;line-height: 20px;">
					<a>{{headline}}</a>
				</div>
				<div class="du2">
					<ul style="list-style: none;">
						<li v-for="(item,key) in datafrom" :key="key">
							<a target="_blank" :title="item.title" @click="opentitle(item)">{{ item.title }}</a>
						</li>
					</ul>
				</div>
				<div style="text-align: center;">
					<el-pagination @current-change="handleCurrentChange" :current-page="current" :page-size="size"
						layout="total, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>

			<div v-if="details" class="ds">
				<div style="padding-top: 20px;margin-bottom: 15px;" class="ds2">
					<el-page-header @back="goBack" :content="headline">
					</el-page-header>
					<div
						style="text-align: center;padding-bottom: 25px;padding-top: 20px;font-weight: bold;font-size: 20px;">
						{{ title }}
					</div>
					<div class="cont-img ql-editor" v-html="content"
						style="line-height: 30px;color: #333;text-align: justify;padding-left: 15px;padding-right: 15px;">
					</div>
					<div style="font-weight: 600;text-align: right;padding-top: 25px;padding-right: 15px;">{{ time }}
					</div>
					<!-- <div style="padding-top: 10px;text-align: right;padding-bottom: 25px;font-weight: 600;">{{ source }}
					</div> -->
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			/* handleSizeChange(val) {
				this.size = val
				this.$post("/industry/pageindustry", {
					headline: this.datafrom[0].headline,
					current: this.current,
					size: this.size
				}).then(resp => {
					this.datafrom = resp.data.records
				})
			}, */
			handleCurrentChange(val) {
				this.current = val
				this.$post("/industry/pageindustry", {
					headline: this.datafrom[0].headline,
					current: this.current,
					size: this.size
				}).then(resp => {
					this.datafrom = resp.data.records
				})
			},
			opentitle(data) {
				this.details = true
				this.title = data.title
				this.content = data.content
				this.time = data.creatTime
				this.headline = data.headline
				this.source = data.source
			},
			goBack() {
				if (this.$route.query.firstdata !== undefined) {
					this.details = false
					this.$router.push("/move")
				} else {
					this.details = false
				}
			}
		},
		data() {
			return {
				current: 1,
				size: null,
				total: 0,
				categorydata: "",
				datafrom: [],
				title: '',
				content: '',
				time: '',
				details: false,
				headline: '',
				source: ''
			};
		},
		created() {
			if (this.$route.query.firstdata !== undefined) {
				this.opentitle(this.$route.query.firstdata)
			} else {
				this.details = false
				this.size = 10
				this.headline = this.$route.query.headline
				this.$post("/industry/pageindustry", {
					headline: this.$route.query.headline,
					current: 1,
					size: this.size
				}).then(resp => {
					console.log(resp)
					this.datafrom = resp.data.records
					this.total = resp.data.total
				})
			}
		},
		watch: {
			$route() {
				if (this.$route.query.firstdata !== undefined) {
					this.opentitle(this.$route.query.firstdata)
				} else {
					this.details = false
					this.size = 10
					this.headline = this.$route.query.headline
					this.$post("/industry/pageindustry", {
						headline: this.$route.query.headline,
						current: 1,
						size: this.size
					}).then(resp => {
						console.log(resp)
						this.datafrom = resp.data.records
						this.total = resp.data.total
					})
				}
			}
			/* mdata(newdata) {
				this.categorydata = newdata[0].category
				if (this.$route.query.firstdata !== undefined) {
					this.opentitle(this.$route.query.firstdata)
				} else {
					this.details = false
					this.size = this.sizes[0]
					this.$post("/industry/pageindustry", {
						headline: newdata[0].headline,
						current: 1,
						size: this.size
					}).then(resp => {
						this.datafrom = resp.data.records
						this.total = resp.data.total
					})
				}
			} */
		}
	}
</script>

<style scoped="scoped">
	@import "~@/assets/css/richText.css";
	.ds {
		padding-bottom: 0 !important;
		margin-top: 10px !important;
		background: #FFF !important;
		min-height: 500px;
		width: 100%;
	}

	.ds2 {
		width: 100% !important;
		min-height: 540px;
		background: #fff;
		margin: auto;
		margin: 0 auto;
		padding-top: 20px;
		padding-bottom: 20px;
		border: 1px solid #dcdcdc;
		width: 95% !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.cont-img>>>img {
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;
	}

	.dn a {
		float: left;
		line-height: 50px;
		text-align: left;
		font-size: 23px;
		color: #0088cc;
	}

	.du2 {
		padding: 10px;
		height: auto !important;
		width: 100%;
		border: 1px solid #c2cfe3;
		box-sizing: border-box;
	}

	.du2 li {
		line-height: 35px;
		height: 35px;
		font-size: 14px;
		overflow: hidden;
		text-indent: 1em;
		background: url(../../assets/img/dina_18.png) no-repeat left;
		position: relative;
	}

	.du2 ul li a {
		right: 95px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: #333;
		position: absolute;
		left: 0px;
		width: 100%;
	}

	.du2 ul li span {
		line-height: 35px !important;
		font-style: normal;
		display: block;
		float: right;
		font-size: 13px;
	}

	/deep/.el-pagination__jump {
		margin-left: 5px;
		font-weight: 400;
		color: #606266;
	}
</style>
